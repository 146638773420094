import "@/lib/error";
import HyperDXBrowser from "@hyperdx/browser";
import type { Attributes } from "@opentelemetry/api";

export interface HyperDX {
  addAction: (action: string, metadata: Attributes) => void;
  recordException: (error: unknown, metadata?: Attributes) => void;
  setGlobalAttributes?: (attributes: Record<string, string>) => void;
  setTraceAttributes?: (attributes: Record<string, string>) => void;
}

export function createHyperDXBrowser(): HyperDX {
  // hyperdx browser only works on the frontend (it checks for window before sending any errors)
  HyperDXBrowser.init({
    apiKey: (process.env.NODE_ENV === "production" && process.env.HYPERDX_API_KEY) || "",
    service: `frontend-${process.env.NODE_ENV}`,
    // this levereges route rewrites in next.config.js. Remove to connect
    // to HyperDX directly.
    url: "/hyperdx",
    tracePropagationTargets: [/distill.fyi/i],
    // this captures all headers and bodies - seems excessive to always be on
    advancedNetworkCapture: false,
    instrumentations: {
      // suppress auto-instrumentation of errors as it's quite noisy
      errors: false,
      // turn off fetch to stop capturing stuff like image loads. xhr is still on.
      fetch: false,
    },
  });
  HyperDXBrowser.setGlobalAttributes({
    gitHash: process.env.GIT_HASH || "",
  });

  return HyperDXBrowser;
}
